import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Section, Title, Box, Text, Button, Span } from "../../components/Core";
import { device } from "../../utils";
import imgCase1 from "../../assets/image/jpeg/nonnose.jpg";
import imgCase2 from "../../assets/image/jpeg/bb.jpg";
import imgCase3 from "../../assets/image/jpeg/bb1.jpg";



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const BlogTitle = styled.p`

font-size:14px;
color: #1a1a1a;
font-weight:600;
padding-top:5px;
padding-bottom:5px;

@media screen and (max-width: 991px)
 {
  font-size:12px;
}

`;

const BlogText = styled.p`

font-size:14px;
color: #999;

@media screen and (max-width: 991px)
 {
  font-size:11px;
}

`;







const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: transparent;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:1;


`;



const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color:#999;



font-size: 1em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 12px;
     text-align:center;
     width: 100%;
 }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const CaseCardStyled = styled(Box)`
  width: 320px;
  transition: 0.4s;
  margin: 0 auto;
font-size:14px;
  .img-container {
    position: relative;
    z-index: 1;
    img {
      border-radius: 8px 8px 0 0;
      max-width: 100%;
    }
  }

  &:hover {
    transform: translateY(-20px);
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${CaseCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`;

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  font-size:14px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.sm} {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const Shape = styled(Box)`
  background: initial;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  bottom: 98%;
  @media ${device.sm} {
    bottom: 99%;
  }
  @media ${device.xl} {
    bottom: 100%;
  }
  svg {
    width: 100%;
    path {
      fill: ${({ theme, bg }) => theme.colors[bg]};
      transition: 0.4s;
    }
  }
`;

const PreTitle = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 16px;
  margin-bottom: 22px;
`;

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  @media ${device.sm} {
    font-size: 18px;
    font-weight: 500;
  }
`;

const CaseCard = ({
  isDark = true,
  bg = "#000",
  img,
  meta = "",
  title = "",
  children = "",
}) => {
  return (
    <CaseCardStyled>
      <div className="img-container">
        <img src={img} alt="Non Surgical Nose Job London" />
        <BtnContainer>
          <Button>View Blog</Button>
        </BtnContainer>
      </div>
      <TextContent bg="#000">
      
        <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle>
        <TitleStyled color={isDark ? "light" : "dark"}>{title}</TitleStyled>
        <Text color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
      </TextContent>
    </CaseCardStyled>
  );
};

const NoseBlogPage = () => {
  return (
    <>
    <Separator />
      {/* <!-- Content section --> */}
      <Section py={4}  bg="#f7f7fb">
      <Box >
        <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3"  data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="false"
              data-aos-delay="50">

        <Row className="  mb-0">
                  <Col lg="6" className="pb-5">
                  <Iwrap>


<Itext as="h2">Non Surgical Rhinoplasty <span>News</span></Itext>
</Iwrap>
      <Secondtext>
   Our Blog
      </Secondtext>
                  </Col>
              
          <Col
              lg="6"
              md="9"
              className="mb-4 mr-0 pr-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >

<div className="slider">
  


  <div className="slides">
    <div className="insideslides" id="slide-1">
   

    <Link to="/blog/non-surgical-nose-jobs-london"> <img src={imgCase1} alt="non surgical nose job london" width="100%"/> <br />
      <BlogTitle color="black">Everything you need to know about Non-Surgical Rhinoplasty</BlogTitle> 
      <BlogText>Non-surgical Rhinoplasty is an aesthetic medical procedure of augmentation to alter and reshape a person's nose without carrying surgery...</BlogText>
      </Link>
             
    </div>
    <div id="slide-2">


    <Link to="/blog/profile-balancing-shape-nose-lips-chin-affect-profile-face"> <img src={imgCase3} alt="Lip Filler London" width="100%"/> <br />
      <BlogTitle color="black">How the shape of the nose, lips and chin can affect the overall profile of the face</BlogTitle> 
      <BlogText>The views on facial attractiveness and youthfulness differ from one individual to another, beside the cultural difference around this topic...</BlogText>
      </Link>
  
    </div>
    <div id="slide-3">

    <Link to="/blog/non-surgical-rhinoplasty-fast-effective-way-improve-side-profile"> <img src={imgCase2} alt="non surgical rhinoplasty london" width="100%"/> <br />
      <BlogTitle color="black">Non-surgical Rhinoplasty: A fast and effective way to improve the side profile</BlogTitle> 
      <BlogText>Have you ever wished your nose looked different? Non-surgical rhinoplasty is the answer if you’re considering a nose job and...</BlogText>
      </Link>
 
    </div>
  
  </div>
</div>
                
            </Col>
           
 
          </Row>
       
        </Container>
      </Box>
      </Section>
      <SeparatorEnd />
    </>
  );
};

export default NoseBlogPage;
